<template>
    <router-link class="navbar-brand" to="/">
        <img src="../assets/images/logo.png" alt="Logo" height="50" />
    </router-link>
</template>

<script>
export default {
    name: "Logo",
};
</script>
