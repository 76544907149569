<template>
    <div>
        <Navbar />
        <div class="container my-5">
            <slot></slot>
        </div>
        <Footer />
    </div>

    <div class="fixed-bottom d-flex justify-content-left align-items-center">
        <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="!cookiesAccepted">
            Este site utiliza cookies para melhorar sua experiência. Ao continuar navegando, você concorda com nossa
            política de cookies.
            <button type="button" class="btn-close" @click="acceptCookies" aria-label="Fechar"></button>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import Navbar from "./Navbar.vue";
import Footer from "./Footer.vue";

export default {
    name: "BaseLayout",
    data() {
        return {
            cookiesAccepted: false
        }
    },
    methods: {
        acceptCookies() {
            Cookies.set('cookiesAccepted', true, { expires: 365 });
            this.cookiesAccepted = true;
        },
    },
    mounted() {
        if (Cookies.get('cookiesAccepted')) {
            this.cookiesAccepted = true;
        }
    },
    components: {
        Navbar,
        Footer,
    },
};
</script>
