<template>
    <div class="maintenance-container">
        <Logo />
        <h1 class="maintenance-title">Em Manutenção</h1>
        <p class="maintenance-message">{{ message }}</p>
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue';

export default {
    name: "Maintenance",
    components: {
        Logo,
    },
    data() {
        return {
            message: "Desculpe pelo transtorno, nosso site está em desenvolvimento e voltará em breve.",
        };
    },
};
</script>

<style scoped>
.maintenance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #212529;
}

.maintenance-title {
    font-size: 3rem;
    margin-top: 2rem;
    color: #eee;
}

.maintenance-message {
    font-size: 1.2rem;
    margin-top: 1rem;
    color: #ccc;
    text-align: center;
    max-width: 600px;
}
</style>
