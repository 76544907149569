<template>
    <section id="welcome" class="vh-100 d-flex flex-column justify-content-center align-items-center text-center">
        <i class="bi bi-cart4 display-1 text-dark"></i>
        <h1 class="display-4 font-weight-bold">{{ typedText }}</h1>
        <p class="lead font-weight-bold">Economize tempo, evite filas e desfrute da comodidade de compras no seu condomínio.
        </p>
        <router-link to="/contato" class="btn btn-lg btn-primary">
            <i class="bi bi-megaphone-fill"></i>
            Quero um Giga Mercado em meu condomínio!
        </router-link>
    </section>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            typedText: ''
        };
    },
    methods: {
        async typeAnimation(text, speed = 100) {
            for (let i = 0; i < text.length; i++) {
                this.typedText += text[i];
                await new Promise((resolve) => setTimeout(resolve, speed));
            }
        },
    },
    mounted() {
        this.typeAnimation("Giga Market4u: Conveniência e Inovação");
    },
};
</script>