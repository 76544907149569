<template>
    <div class="newsletter-section py-5 bg-light">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h2>Receba nossas novidades</h2>
                    <p>Inscreva-se na nossa newsletter e fique por dentro das últimas notícias, ofertas e promoções
                        exclusivas da Market4U.</p>
                </div>
                <div class="col-md-6">
                    <div class="newsletter-form">
                        <form>
                            <label for="newsletter-email">Inscreva-se na nossa newsletter</label>
                            <div class="form-row">
                                <div class="col mb-3">
                                    <input type="email" class="form-control" id="newsletter-email"
                                        placeholder="Seu melhor e-mail" required>
                                    <div class="invalid-feedback">
                                        Por favor, digite um endereço de e-mail válido.
                                    </div>
                                </div>
                                <div class="col">
                                    <button type="submit" class="btn btn-primary btn-block">Inscreva-se</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- Texto muted explicando o que acontece ao se cadastrar -->
                <div class="col-md-12">
                    <small class="form-text text-muted">
                        Ao se cadastrar, você concorda com a nossa <a href="#">Política de Privacidade</a> e com o
                        recebimento de nossas novidades e promoções.
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Newsletter',
}
</script>

<style scoped>
.newsletter-section {
    text-align: center;
}

.newsletter-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control {
    margin-right: 10px;
}
</style>
