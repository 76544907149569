<template>
    <div class="container my-5">
        <h2 class="mb-4">Contato</h2>
        <form class="row g-3">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6 mb-3 text-start">
                        <label for="firstName" class="form-label">Nome</label>
                        <input type="text" class="form-control" id="firstName" placeholder="Seu nome" required>
                    </div>
                    <div class="col-md-6 mb-3 text-start">
                        <label for="lastName" class="form-label">Sobrenome</label>
                        <input type="text" class="form-control" id="lastName" placeholder="Seu sobrenome" required>
                    </div>
                </div>
                <div class="mb-3 text-start">
                    <label for="email" class="form-label">E-mail</label>
                    <input type="email" class="form-control" id="email" placeholder="email@exemplo.com" required>
                </div>
                <div class="mb-3 text-start">
                    <label for="phone" class="form-label">Telefone</label>
                    <input type="tel" class="form-control" id="phone" placeholder="(00) 00000-0000" required
                        v-mask="'(##) #####-####'">
                </div>
                <div class="mb-3 text-start">
                    <label for="subject" class="form-label">Assunto</label>
                    <input type="text" class="form-control" id="subject" placeholder="Assunto da mensagem" required>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-4">
                    <label for="message" class="form-label">Mensagem</label>
                    <textarea class="form-control w-100" id="message" rows="8" placeholder="Digite sua mensagem"
                        required></textarea>
                </div>
                <div class="form-check mb-3 text-start">
                    <input class="form-check-input" type="checkbox" id="emailConfirmation" />
                    <label class="form-check-label" for="emailConfirmation">
                        Ao selecionar esta caixa e enviar seus dados, você nos autoriza a enviar informações relevantes por
                        e-mail. Você pode cancelar a qualquer momento.
                    </label>
                </div>
            </div>
            <div class="col-md-12 text-start">
                <button type="submit" class="btn btn-primary">Enviar</button>
            </div>
        </form>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
    name: 'Contact',
    directives: { mask },
};
</script>
