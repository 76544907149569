// src/store.js
import { createStore } from "vuex";

const store = createStore({
  state: {
    // Defina o estado inicial aqui
    // Exemplo:
    count: 0,
    AppName: process.env.VUE_APP_APP_NAME || "GigaMarket4u",
  },
  mutations: {
    // Defina suas mutações aqui
    // Exemplo:
    increment(state) {
      state.count++;
    },
    decrement(state) {
      state.count--;
    },
  },
  actions: {
    // Defina suas ações aqui
    // Exemplo:
    incrementAsync({ commit }) {
      setTimeout(() => {
        commit("increment");
      }, 1000);
    },
    decrementAsync({ commit }) {
      setTimeout(() => {
        commit("decrement");
      }, 1000);
    },
  },
  getters: {
    // Defina seus getters aqui
    // Exemplo:
    doubleCount(state) {
      return state.count * 2;
    },
    getAppName(state) {
      return state.AppName;
    },
  },
});

export default store;
