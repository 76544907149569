<template>
    <footer class="bg-dark text-white py-4">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <Logo />
                    <ul class="list-inline">
                        <li class="list-inline-item" v-for="route in $router.options.routes" :key="route.name">
                            <router-link :to="route.path" class="text-white">{{ route.meta.title }}</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <h5 class="text-white">Sobre a Giga Market4u</h5>
                    <p class="text-white">
                        A Giga Market4u é um mercado autônomo e inteligente que utiliza tecnologia de ponta para oferecer
                        uma experiência de compra única aos nossos clientes.
                    </p>
                </div>
                <div class="col-md-3">
                    <h5 class="text-white">Redes Sociais</h5>
                    <ul class="list-unstyled">
                        <li>
                            <a href="https://www.facebook.com/sua_pagina" class="text-white">
                                <i class="bi bi-facebook text-primary mr-2"
                                    style="margin-right: 0.5rem;"></i>
                                <span>Facebook</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/sua_pagina" class="text-white">
                                <i class="bi bi-instagram text-danger mr-2"
                                    style="margin-right: 0.5rem;"></i>
                                <span>Instagram</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitter.com/sua_pagina" class="text-white">
                                <i class="bi bi-twitter text-info mr-2"
                                    style="margin-right: 0.5rem;"></i>
                                <span>Twitter</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/sua_pagina" class="text-white">
                                <i class="bi bi-linkedin text-primary mr-2"
                                    style="margin-right: 0.5rem;"></i>
                                <span>LinkedIn</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col text-center">
                    <p>&copy; {{ new Date().getFullYear() }}
                        <router-link to="/" class="text-white">
                            {{ getAppName }}
                        </router-link> Todos os direitos reservados.
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "@/components/Logo.vue";

export default {
    computed: {
        ...mapGetters(["getAppName"]),
    },
    components: {
        Logo,
    },
};
</script>

<style scoped>
a.text-white:hover {
    text-decoration: none;
    color: #ccc;
}
</style>
