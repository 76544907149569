<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNav"
                aria-controls="offcanvasNav" aria-expanded="false" aria-label="Toggle navigation">
                <i class="bi bi-list"></i>
            </button>

            <div class="offcanvas offcanvas-end bg-dark" tabindex="-1" id="offcanvasNav"
                aria-labelledby="offcanvasNavLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasNavLabel">Menu</h5>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <ul class="navbar-nav">
                        <li class="nav-item" v-for="route in $router.options.routes" :key="route.meta.title">
                            <router-link :to="route.path" class="nav-link" :class="{ active: isActive(route.path) }"
                                @click.native="navigateAndClose(route.path)">
                                {{ route.meta.title }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Menu",
    methods: {
        isActive(link) {
            return this.$route.path === link;
        },
        navigateAndClose(link) {
            this.$router.push(link).then(() => {
                const offcanvas = document.getElementById("offcanvasNav");
                const offcanvasClose = offcanvas.querySelector(
                    ".btn-close"
                );

                offcanvasClose.click();
            });
        }
    },
};
</script>

<style>
.navbar-toggler .bi {
    font-size: 1.25rem;
}

.offcanvas {
    width: 100%;
}
</style>
