<template>
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1>Sobre a Empresa</h1>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <p class="fs-5 lh-base">
                    Nossa empresa está revolucionando as compras em condomínios com um modelo de negócios inovador e
                    tecnologia disruptiva. Nosso objetivo é proporcionar aos condôminos uma experiência de compra prática e
                    eficiente, eliminando a necessidade de deslocamento até um mercado convencional. Com a instalação de
                    pequenos mercados autônomos dentro dos condomínios, os moradores têm a facilidade de fazer suas compras
                    a qualquer momento do dia, sem precisar sair de casa. Isso representa uma grande mudança para os
                    moradores de condomínios, que agora podem ter uma experiência de compra mais conveniente e eficiente.
                </p>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-md-4 mb-3">
                <img src="https://via.placeholder.com/400x300.png"
                    alt="Pequenos mercados autônomos dentro dos próprios condomínios" class="img-fluid">
            </div>
            <div class="col-md-8">
                <p class="fs-5 lh-base">
                    Nossa tecnologia disruptiva permite que os clientes tenham acesso a informações valiosas sobre os
                    produtos e serviços disponíveis, permitindo uma tomada de decisão mais consciente e assertiva. Com a
                    nossa solução de pequenos mercados autônomos dentro dos condomínios, os nossos clientes desfrutam de
                maior comodidade, segurança e facilidade em seu dia a dia, sem a presença de atendentes, o que permite
                que o processo de compras seja mais rápido e eficiente. Além disso, a instalação dos pequenos mercados
                autônomos dentro dos condomínios é uma solução ecológica e sustentável, reduzindo a necessidade de
                deslocamentos.
            </p>
        </div>
    </div>
    <div class="row my-5">
        <div class="col-md-8 mb-3">
            <p class="fs-5 lh-base">
                Nossa rede é sustentada por tecnologia de ponta desenvolvida internamente, o que nos permite oferecer
                aos nossos clientes uma experiência de compra inigualável e aos nossos franqueados uma gestão
                inteligente e eficiente de suas lojas. Através de sistemas avançados de controle e monitoramento, nossos franqueados têm acesso a informações valiosas sobre o desempenho e o funcionamento de suas lojas. Isso permite que tomem decisões mais assertivas e alcancem o sucesso do negócio com mais facilidade.
    </p>
    </div>
    <div class="col-md-4">
    <img src="https://via.placeholder.com/400x300.png" alt="Tecnologia de rede" class="img-fluid">
    </div>
    </div>
    <div class="row mt-3">
    <div class="col-12">
    <p class="fs-5 lh-base">
    Ao escolher a nossa solução de pequenos mercados autônomos e tecnologia disruptiva, os condôminos e franqueados têm acesso a uma alternativa inovadora e eficaz para otimizar seu tempo e recursos, resultando em uma vida mais conveniente e prática dentro do ambiente residencial. Além disso, nossos clientes têm a garantia de uma experiência de compra excepcional, enquanto nossos franqueados se beneficiam de uma gestão inteligente de suas lojas e informações valiosas sobre seu desempenho. A escolha de nossa solução também contribui para um mundo mais sustentável, reduzindo a necessidade de deslocamentos e, portanto, ajudando a preservar o meio ambiente.
    </p>
    </div>
    </div>

      </div>
</template>
<script>
export default {
    name: "About",
};
</script>
<style scoped>
@media (max-width: 767px) {
    .fs-5 {
        font-size: 1rem !important;
    }
}
</style>