<template>
    <div>
        <Welcome />
        <hr class="mb-5">
        <Features />
        <hr class="mb-5">
        <Newsletters />
    </div>
</template>

<script>
import Welcome from '@/components/home/Welcome.vue';
import Features from '@/components/home/Features.vue';
import Newsletters from '@/components/home/Newsletters.vue';

export default {
    name: "Home",
    components: {
        Welcome,
        Features,
        Newsletters,
    },
};
</script>