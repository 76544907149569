<template>
    <section class="market4u-container">
        <div class="market4u-content">
            <h2 class="market4u-title">Market4U: Revolucionando suas compras com praticidade e eficiência</h2>
            <p class="market4u-text">Com a nossa solução de pequenos mercados autônomos dentro dos condomínios, os nossos
                clientes desfrutam de uma nova forma de fazer compras. Essa solução é prática e eficiente, permitindo que os
                moradores dos condomínios realizem suas compras a qualquer momento do dia, sem precisar se deslocar para
                mercados convencionais.</p>
            <router-link to="/sobre" class="market4u-button">Saiba mais</router-link>
        </div>

        <!-- Market4u Image Response -->
        <div class="market4u-image">
            <img src="https://via.placeholder.com/400x400" alt="Imagem ilustrativa do Market4U">
        </div>
    </section>
</template>

<script>
export default {
    name: 'Features',
}
</script>

<style scoped>
.market4u-container {
    display: flex;
    align-items: center;
}

.market4u-content {
    width: 50%;
    text-align: center;
}

.market4u-title {
    color: #3d3d3d;
    font-size: 2.5rem;
    font-weight: bold;
}

.market4u-text {
    color: #666;
    font-size: 1.2rem;
}

.market4u-button {
    display: inline-block;
    background-color: #f7a700;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
}

.market4u-image {
    width: 50%;
}

@media (max-width: 768px) {
    .market4u-container {
        flex-direction: column;
    }

    .market4u-content,
    .market4u-image {
        width: 100%;
    }
    
    /* Image responsive */
    .market4u-image img {
        margin-top: 20px;
        width: 100%;
    }
}
</style>
