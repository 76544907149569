import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
import About from './views/About.vue';
import Contact from './views/Contact.vue';
import Gallery from './views/Gallery.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      meta: { title: "Página Inicial" },
      component: Home,
    },
    {
      path: "/sobre",
      name: "About",
      meta: { title: "Sobre" },
      component: About,
    },
    {
      path: "/galeria",
      name: "Gallery",
      meta: { title: "Galeria" },
      component: Gallery,
    },
    {
      path: "/contato",
      name: "Contact",
      meta: { title: "Contato" },
      component: Contact,
    },
  ],
});

router.beforeEach((to) => {
  document.title = `${to.meta.title} | Giga Market4u`;
});

export default router;
