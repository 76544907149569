<template>
  <div id="app-vue">
    <BaseLayout v-if="!maintenance">
      <router-view></router-view>
      <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer" class="whatsapp-btn">
        <span class="whatsapp-btn-text">Peça já seu Giga Mercado!</span>
        <i class="bi bi-whatsapp whatsapp-btn-icon"></i>
      </a>
    </BaseLayout>
    <Maintenance v-else></Maintenance>
  </div>
</template>

<script>
import BaseLayout from "./components/BaseLayout.vue";
import Maintenance from "./views/Maintenance.vue";

export default {
  name: "App",
  components: {
    BaseLayout,
    Maintenance,
  },
  data() {
    return {
      maintenance: false
    };
  },
  created() {
    this.maintenance = process.env.VUE_APP_MAINTENANCE === 'true';
  },
  // * Console log on maintenance mode
  mounted() {
    if (this.maintenance) {
      console.log(
        "%c⚠️ ATENÇÃO ⚠️",
        "color: #fff; background: #f00; font-size: 24px; font-weight: bold; padding: 10px;"
      );
      console.log(
        "%cEste site está em manutenção. Por favor, não tente fazer pedidos ou acessar outras funcionalidades.",
        "color: #000; font-size: 16px; font-weight: bold; padding: 10px;"
      );
    } else {
      console.log(
        "%c👋 Olá, desenvolvedor!",
        "color: #fff; background: #000; font-size: 24px; font-weight: bold; padding: 10px;"
      );
      console.log(
        "%cSe você está vendo isso, é porque está tentando acessar o console do navegador. Por favor, não tente fazer pedidos ou acessar outras funcionalidades.",
        "color: #000; font-size: 16px; font-weight: bold; padding: 10px;"
      );
    }
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.whatsapp-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25D366;
  border-radius: 50px;
  padding: 12px 20px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  text-decoration: none;
}

.whatsapp-btn:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.whatsapp-btn-text {
  color: white;
  font-weight: bold;
  margin-right: 10px;
}

.whatsapp-btn-icon {
  color: white;
  font-size: 24px;
}
</style>
