<template>
    <div>
        <h1 class="mb-4">Galeria</h1>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            <div class="col" v-for="(image, index) in images" :key="index">
                <div class="card h-100 text-white">
                    <img :src="image.url" class="card-img" :alt="image.title">
                    <div class="card-img-overlay d-flex flex-column justify-content-end">
                        <div class="card-img-overlay d-flex flex-column justify-content-end">
                            <h5 class="card-title">{{ image.title }}</h5>
                            <p class="card-text">{{ image.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Gallery",
    data() {
        return {
            images: [
                {
                    title: "Imagem 1",
                    description: "Descrição da imagem 1.",
                    url: "https://via.placeholder.com/1024x768",
                },
                {
                    title: "Imagem 2",
                    description: "Descrição da imagem 2.",
                    url: "https://via.placeholder.com/1024x768",
                },
                {
                    title: "Imagem 3",
                    description: "Descrição da imagem 3.",
                    url: "https://via.placeholder.com/1024x768",
                },
                {
                    title: "Imagem 4",
                    description: "Descrição da imagem 4.",
                    url: "https://via.placeholder.com/1024x768",
                },
                {
                    title: "Imagem 5",
                    description: "Descrição da imagem 5.",
                    url: "https://via.placeholder.com/1024x768",
                },
                {
                    title: "Imagem 6",
                    description: "Descrição da imagem 6.",
                    url: "https://via.placeholder.com/1024x768",
                },
            ],
        };
    },
};
</script>

<style scoped>
.row {
    margin-left: -15px;
    margin-right: -15px;
}
</style>
