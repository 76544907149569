<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
            <Logo />
            <Menu />
        </div>
    </nav>
</template>

<script>
import Logo from "./Logo.vue";
import Menu from "./Menu.vue";

export default {
    name: "Navbar",
    components: {
        Logo,
        Menu,
    },
};
</script>
